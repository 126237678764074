import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';

const sectionRelated = function() {
    const sectionWrapper = document.querySelectorAll('.js-related-wrapper');

    if (sectionWrapper.length > 0) {
        sectionWrapper.forEach(el => {
            let slider = el.querySelector('.js-swiper');

            if (slider) {
                Swiper.use([Pagination, Navigation]);
    
                new Swiper(slider, {
                    speed: 600,
                    updateOnWindowResize: true,
                    spaceBetween: 0,
                    slidesPerView: 1,
                    // pagination: {
                    //     el: el.querySelector('.swiper-pagination'),
                    // },
                    paginationClickable: true,
                    navigation: {
                        prevEl: el.querySelector('.swiper-button-prev'),
                        nextEl: el.querySelector('.swiper-button-next'),
                    },
                    breakpoints: {
                        769: {
                            slidesPerView: 2
                        },
                        1025: {
                            slidesPerView: 3
                        },
                    }
                });
            }
        })
    }
};

export {sectionRelated};